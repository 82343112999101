import { DatePipe, NgIf } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FailedOrderModel } from 'src/app/core/domain/order/failed-orders.model';
import { BaseComponent } from '../../base/base.component';
import { dateFormat } from '../../shared/constants';
import { FailedOrderPresenter } from './failed-order-presenter/failed-order.presenter';
import { FailedOrderSideEffect } from './failed-order-presenter/failed-order.sideEffects';
import { FailedOrderViewEvents } from './failed-order-presenter/failed-order.viewEvents';
import { FailedOrderViewState } from './failed-order-presenter/failed-order.viewState';

@Component({
  selector: 'app-failed-order-overview',
  standalone: true,
  imports: [TranslateModule, NgIf, DatePipe],
  templateUrl: './failed-order-overview.component.html',
  styleUrls: ['./failed-order-overview.component.scss'],
  providers: [FailedOrderPresenter],
})
export class FailedOrderOverviewComponent extends BaseComponent<
  FailedOrderPresenter,
  FailedOrderViewState,
  FailedOrderViewEvents,
  FailedOrderSideEffect
> {
  @Input() failedOrder: FailedOrderModel;

  @Input() numberOfFailedOrders: number;

  public dateFormat = dateFormat;

  public presenter: FailedOrderPresenter = inject(FailedOrderPresenter);

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
      failedOrder: this.failedOrder,
      numberOfFailedOrders: this.numberOfFailedOrders.toString(),
    });
  }
}

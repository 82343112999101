<p class="title">{{ 'ORDERS_PAGE.LEADS.TITLE' | translate }}</p>
<loader [loading]="loading"></loader>
<app-lead-filters
  (searchTriggered)="onFilterSearch($event)"
  [resetTriggered]="triggerFilterReset"
></app-lead-filters>
<div class="row">
  <div class="col-12 disp-end">
    <div class="excel-tooltip">
      <button class="btn btn-excel" (click)="viewMyLeadsExtract()">
        <img loading="lazy" class="excel-i" src="../../../assets/img/excel-white.svg" />
        {{ 'ORDERS_PAGE.LEADS.DOWNLOAD_LEADS' | translate }}
      </button>
      <span class="tooltip-text">
        {{ 'ORDERS_PAGE.EXCEL_SHEET_TOOLTIP' | translate }}
      </span>
    </div>
  </div>
</div>
<div class="lead-filters">
  <form [formGroup]="searchForm">
    <div class="row">
      <hr />
      <div class="col-12">
        <div class="disp-end">
          <div class="date-filter">
            <div class="date-picker-group">
              <mat-form-field appearance="outline" class="date-picker">
                <input
                  matInput
                  [max]="fromDateMax"
                  [matDatepicker]="leadDateSince"
                  class="date-picker-input"
                  [ngClass]="{
                    error: !searchForm.get('leadDateSince').valid || searchForm?.errors?.fromDateShouldBeLessThanToDate,
                  }"
                  placeholder="{{ 'WALLET.TRANSACTION_HISTORY.START_DATE' | translate }}"
                  formControlName="leadDateSince"
                  (dateChange)="filterSearch($event, 'leadDateSince')"
                />
                <mat-datepicker-toggle matPrefix [for]="leadDateSince">
                  <i matDatepickerToggleIcon class="icon icon-wallet-date-picker-icon"></i>
                </mat-datepicker-toggle>
                <mat-datepicker #leadDateSince></mat-datepicker>
                <button
                  class="clear-btn date-clear"
                  *ngIf="searchForm.get('leadDateSince').value"
                  mat-button
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="clearSelection($event, 'leadDateSince')"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
              <p class="date-separator">-</p>
              <mat-form-field appearance="outline" class="date-picker">
                <input
                  matInput
                  [min]="toDateMin"
                  [matDatepicker]="leadDateTo"
                  class="date-picker-input"
                  [ngClass]="{
                    error: !searchForm.get('leadDateTo').valid || searchForm?.errors?.fromDateShouldBeLessThanToDate,
                  }"
                  placeholder="{{ 'WALLET.TRANSACTION_HISTORY.END_DATE' | translate }}"
                  formControlName="leadDateTo"
                  (dateChange)="filterSearch($event, 'leadDateTo')"
                />
                <mat-datepicker-toggle matPrefix [for]="leadDateTo">
                  <i matDatepickerToggleIcon class="icon icon-wallet-date-picker-icon"></i>
                </mat-datepicker-toggle>
                <mat-datepicker #leadDateTo></mat-datepicker>
                <button
                  class="clear-btn date-clear"
                  *ngIf="searchForm.get('leadDateTo').value"
                  mat-button
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="clearSelection($event, 'leadDateTo')"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
          </div>
          <div class="country-dropdown">
            <mat-form-field appearance="outline" class="order-filters__select">
              <mat-select
                formControlName="country"
                [placeholder]="'ORDERS_PAGE.FILTERS.COUNTRY_PLACEHOLDER' | translate"
                (selectionChange)="fetchLeadList()"
              >
                <mat-option *ngFor="let country of countries" [value]="country.isoCode3">{{
                  trans(country.isoCode3)
                }}</mat-option>
              </mat-select>
              <button
                class="clear-btn"
                *ngIf="searchForm.get('country').value"
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearSelection($event, 'country')"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<ng-container *ngIf="leadList?.length > 0; else noLeadsFound">
  <div class="lead-card-container">
    <app-lead-card *ngFor="let lead of leadList" [lead]="lead"> </app-lead-card>
  </div>
  <div class="row justify-content-center paginate">
    <div *ngIf="showPagination" class="col-xs-6">
      <div class="pagination-wrapper">
        <app-taager-pagination
          [boundaryLinks]="showBoundaryLinks"
          [totalItems]="noOfItems"
          [currentPage]="currentPage"
          [itemsPerPage]="maxItemPerPage"
          [maxSize]="maxItemPerPage"
          [previousText]="' '"
          [nextText]="' '"
          [firstText]="isEnglishLanguage ? 'First' : 'الأولي'"
          [lastText]="isEnglishLanguage ? 'Last' : 'الأخيرة'"
          [scrollToTop$]="scrollToTop$"
          [scope]="'orders'"
          (pageChanged$)="pageChanged($event)"
        ></app-taager-pagination>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #noLeadsFound>
  <div class="no-leads-container" *ngIf="!loading">
    <img src="assets/img/empty-leads.svg" />
    <p class="body1--bold head-title">{{ 'ORDERS_PAGE.LEADS.NO_LEADS_AVAILABLE' | translate }}</p>
    <p class="description">
      {{ 'ORDERS_PAGE.LEADS.NO_LEADS_DETAIL' | translate }}
      <span (click)="openChat()">{{
        'ORDERS_PAGE.LEADS.CONTACT_CUSTOMER_SUPPORT' | translate
      }}</span>
    </p>
  </div>
</ng-template>

<div class="order-filters" *ngIf="!failedOrdersTabSelected">
  <form [formGroup]="orderSearchFilterForm" (ngSubmit)="onClickSearchButton()">
    <div class="order-filters__row">
      <mat-form-field appearance="outline" class="order-filters__long-input">
        <input
          matInput
          formControlName="searchKey"
          [placeholder]="'ORDERS_PAGE.FILTERS.TEXT_SEARCH_PLACEHOLDER' | translate"
        />
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="outline" class="order-filters__input">
        <input
          matInput
          formControlName="orderId"
          [placeholder]="'ORDERS_PAGE.FILTERS.ORDER_SEARCH_PLACEHOLDER' | translate"
        />
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="order-filters__row">
      <mat-form-field appearance="outline" class="order-filters__date-picker">
        <input
          matInput
          formControlName="fromDate"
          [matDatepicker]="fromPicker"
          [placeholder]="'ORDERS_PAGE.FILTERS.FROM_DATE_PLACEHOLDER' | translate"
        />
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="order-filters__date-picker">
        <input
          matInput
          formControlName="toDate"
          [matDatepicker]="toPicker"
          [placeholder]="'ORDERS_PAGE.FILTERS.TO_DATE_PLACEHOLDER' | translate"
        />
        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
        <mat-datepicker #toPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="order-filters__select">
        <mat-select
          formControlName="country"
          [placeholder]="'ORDERS_PAGE.FILTERS.COUNTRY_PLACEHOLDER' | translate"
        >
          <mat-option *ngFor="let country of countries" [value]="country.isoCode3">{{
            trans(country.isoCode3)
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="order-filters__select">
        <mat-select
          formControlName="status"
          [placeholder]="'ORDERS_PAGE.FILTERS.ORDER_STATUS_PLACEHOLDER' | translate"
        >
          <ng-container *ngIf="shouldShowOrdersRevamp; else showOtherStatus">
            <mat-option
              *ngFor="let status of orderStatusList?.data"
              [value]="status.status"
              [ngStyle]="{ display: isChildOrderTabActive ? 'none' : 'flex' }"
              >{{
                isEnglishLanguage ? status.detailedStatus?.en : status.detailedStatus?.ar
              }}</mat-option
            >
          </ng-container>
          <ng-template #showOtherStatus>
            <mat-option
              *ngFor="let status of orderStatuses"
              [value]="status.statusInEnglish"
              [ngStyle]="{ display: isChildOrderTabActive ? 'none' : 'flex' }"
              >{{ status.statusTranslationKey | translate }}</mat-option
            >
            <mat-option
              *ngFor="let status of childOrderStatuses"
              [value]="status.statusInEnglish"
              [ngStyle]="{ display: isChildOrderTabActive ? 'flex' : 'none' }"
              >{{ status.statusTranslationKey | translate }}</mat-option
            >
          </ng-template>
        </mat-select>
      </mat-form-field>

      <button
        class="order-filters__button order-filters__button--solid"
        [disabled]="
          !orderSearchFilterForm.value.searchKey &&
          !orderSearchFilterForm.value.status &&
          !orderSearchFilterForm.value.orderId &&
          !orderSearchFilterForm.value.fromDate &&
          !orderSearchFilterForm.value.toDate &&
          !orderSearchFilterForm.value.country
        "
      >
        {{ 'ORDERS_PAGE.FILTERS.SEARCH_BTN_LABEL' | translate }}
      </button>

      <button
        class="order-filters__button order-filters__button--stroked"
        (click)="onClearFilters()"
        type="button"
        [disabled]="
          !orderSearchFilterForm.value.searchKey &&
          !orderSearchFilterForm.value.status &&
          !orderSearchFilterForm.value.orderId &&
          !orderSearchFilterForm.value.fromDate &&
          !orderSearchFilterForm.value.toDate &&
          !orderSearchFilterForm.value.country
        "
      >
        {{ 'ORDERS_PAGE.FILTERS.NEW_BTN_LABEL' | translate }}
      </button>
    </div>
  </form>
</div>
